<template>
  <div class="access-restricted">
    <v-container class="text-center">
      <v-row>
        <v-col>
          <h2 class="headline mb-4">
            Ограниченный доступ
            <v-icon large>mdi-alert-circle-outline</v-icon>
          </h2>
          <p>
            Вы находитесь в стартовом режиме приложения. Некоторые функции могут быть недоступны.
          </p>
          <p>
            Чтобы получить доступ ко всем возможностям, пожалуйста, обновите вашу подписку.
          </p>
          <p>
            Свяжитесь с нашей службой поддержки или перейдите в раздел "Мой аккаунт" для получения информации об обновлении.
          </p>
          <v-btn color="#FFA500" outlined @click="showDialog = true">Запросить доступ</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- Диалоговое окно -->
    <v-dialog v-model="showDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">Ограниченный доступ</span>
        </v-card-title>
        <v-card-text>
          <p>
            Вы находитесь в стартовом режиме приложения. Некоторые функции могут быть недоступны.
          </p>
          <p>
            Чтобы получить доступ ко всем возможностям, пожалуйста, обновите вашу подписку.
          </p>
          <p>
            Свяжитесь с нашей службой поддержки или перейдите в раздел "Мой аккаунт" для получения информации об обновлении.
          </p>

          <!-- Поле ввода номера телефона -->
          <v-text-field
            v-if="phoneInputVisible"
            v-model="phoneNumber"
            label="Введите номер телефона"
            outlined
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#FFA500" text @click="showDialog = false">Закрыть</v-btn>
          <v-btn color="#FFA500" style="color: white;" @click="handleRequestAccess">
            {{ phoneInputVisible ? "Отправить номер" : "Запросить доступ" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import user from "../../../store/auth/index";
import toast from "../../../plugins/toast";
import { CompaniesApiUrls } from "../../../services/accommodationRequests/companies.api";
export default {
  data() {
    return {
      showDialog: false,       // Управляет показом диалогового окна
      phoneInputVisible: false, // Управляет показом поля ввода телефона
      phoneNumber: "",         // Поле для ввода номера телефона
    };
  },
  methods: {
    async handleRequestAccess() {
      if (!this.phoneInputVisible) {
        this.phoneInputVisible = true;
      } else {
        if (!this.phoneNumber) {
          toast.error("Введите номер телефона!");
          return;
        }
        try {
          let companyId = user.getters.getCompany;
          let resp = await CompaniesApiUrls.changeTarif(companyId, this.phoneNumber);
          console.log("Response:", resp);
          toast.success("Запрос отправлен успешно!");
          this.showDialog = false; // Закрываем диалоговое окно
        } catch (error) {
          console.error("Error:", error);
          toast.error("Произошла ошибка. Попробуйте ещё раз.");
        }
      }
    },
  },
};
</script>
